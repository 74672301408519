/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import styles from "./UploadDoc.module.scss";
import { Box, Stack, Typography } from '@mui/material';
import { IOpenLoanDetailsResponse } from "../../../../clients/LoanClient";
import { useNavigate } from 'react-router-dom';
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import { navigateToDocumentUpload } from '../../../../utils/Helpers/NavigationHelper';
import { useTracking } from "../../../../Tracking/TrackingContext";
import QuickestIvOptionIcon from '../../../../assets/img/iv-quickest-option-icon.png';
import SecondaryButton from '../../../../components/Buttons/SecondaryButton';
import { RedirectToTruId } from '../../../Wizard/JourneyPages/IncomeVerification/Utilities/OpenBankingRedirectUrlUtils';
import {
  OpenBankingRedirectUrlRequestErrorDialog
} from '../../../Wizard/JourneyPages/IncomeVerification/OpenBankingRedirectUrlRequestErrorDialog';
import useLoanClient from '../../../../hooks/loan/Client';
import { formatMoney } from '../../../../utils/Helpers/SliderHelperFunctions';
import Loading from '../../../../components/Loading/Loading';
import { useAppSettings } from '../../../../contexts/AppSettingsContext';
import { IGetPersonalDetailsResponse } from '../../../../clients/AccountClient';

interface OpenLoan {
  openLoanResponse: IOpenLoanDetailsResponse;
  personalDetailsResponse: IGetPersonalDetailsResponse
}
const UploadDoc: React.FunctionComponent<OpenLoan> = ({ openLoanResponse, personalDetailsResponse}: OpenLoan) => {
  const { loanAmount } = openLoanResponse.quotationData || {};
  const { loanId } = openLoanResponse.loanData || {};
  const navigate = useNavigate();
  const { isExistingCustomer } = useTracking();
  const [openErrorDialogPopup, setOpenErrorDialogPopup] = useState(false);
  const loanClient = useLoanClient();
  const [isLoading, setIsLoading] = useState(false);
  const openBankingCompleted = openLoanResponse.loanStateResponse?.avaInfo?.openBankingCompleted;
  const appSettings = useAppSettings();
  const openBankingAutoApprovalEnabled = appSettings.incomeVerification.openBankingAutoApprovalEnabled;
  const bankName = personalDetailsResponse.personalDetails?.bank?.bankName;
  const customerHasCapitecBankAccount = bankName == 'Capitec';
  const showConnectToBankButton = !openBankingCompleted && openBankingAutoApprovalEnabled && !customerHasCapitecBankAccount;

  const redirectToDocumentUpload = () => {
    navigateToDocumentUpload(navigate, loanId ?? "", isExistingCustomer);
  };

  const handleRedirect = async () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    await RedirectToTruId(loanClient, loanId!, setOpenErrorDialogPopup, setIsLoading);
  };

  const handleErrorDialogPopupClose = () => {
    setOpenErrorDialogPopup(false);
  };

  return (
    <>
      <OpenBankingRedirectUrlRequestErrorDialog
        handleDialogClose={handleErrorDialogPopupClose}
        navigateToDocUploadPage={redirectToDocumentUpload}
        open={openErrorDialogPopup} />

      <Box className={styles["uploadDoc-container"]}>
        <Typography className={styles["uploadDoc-title"]}>
          Connect to your bank
        </Typography>

        <Typography className={styles['loan-amount']}>
          <b>R {formatMoney(loanAmount  ?? 0)}</b>
        </Typography>
        <p style={{textAlign: "center"}}> Application Amount </p>

        <Typography style={{paddingTop: '20px'}}>
          To continue with your loan application, we need to verify your income. Please choose your preferred method.
        </Typography>
        {/*<Typography>*/}
        {/*  Application valid until: <b>{formatDate(validUntil)}</b>.*/}
        {/*</Typography>*/}

        {isLoading && <Loading />}

        <Stack
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: { xs: 'column', xmd: 'row-reverse', md: 'row-reverse' },
            justifyContent: { xs: 'initial', md: 'space-between' },
            marginTop: { xs: '50px', md: '100px' },
            marginBottom: {xs: '30px'},
          }}
        >
          { showConnectToBankButton
            &&
            <Box className={styles['connect-to-bank-div']}>
              <img src={QuickestIvOptionIcon} className={styles['iv-quickest-option-icon']} alt="Quickest" />

              <PrimaryButton onClick={handleRedirect} disabled={isLoading} >
                <Typography variant="body1" fontSize="1.5rem" fontWeight={300}>
                  Connect to bank
                </Typography>
              </PrimaryButton>
            </Box>
          }

          { !showConnectToBankButton ? (
            <PrimaryButton onClick={redirectToDocumentUpload} disabled={isLoading}>
              <Typography variant="body1" fontSize="1.5rem" fontWeight={300}>
                Upload documents
              </Typography>
            </PrimaryButton>
          ) : (
            <SecondaryButton onClick={redirectToDocumentUpload} disabled={isLoading}>
              <Typography variant="body1" fontSize="1.5rem" fontWeight={300}>
                Upload documents
              </Typography>
            </SecondaryButton>
          )}

        </Stack>

      </Box>
    </>
  );
};

export default UploadDoc;
