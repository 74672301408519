import React, { useCallback, useEffect, useMemo } from 'react';
import { Box, Container, Stack, Typography } from '@mui/material';
import { theme } from '../../../../../theme/Theme';
import CreditLifeBreakdown from '../../../../../components/CreditLifePlus/CreditLifeBreakdown';
import { useLocation, useNavigate } from 'react-router-dom';
import { GetInstallmentLoanQuotationResponse, GetShortTermLoanQuotationResponse } from '../../../../../clients/LoanClient';
import TextButton from '../../../../../components/Buttons/TextButton';
import PrimaryButton from '../../../../../components/Buttons/PrimaryButton';
import { useWizardOutletContext } from '../../../Wizard';

const CreditLifeLoanRepaymentDetails = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const quotation: GetInstallmentLoanQuotationResponse | GetShortTermLoanQuotationResponse | undefined = useMemo(() => location.state['quotation'] ?? undefined, [location]);
    const creditLifeSaving: number | undefined = location.state['creditLifeSavings'] ?? undefined;
    const { goToProcessing } = useWizardOutletContext();
    const deathBenefitOptedIn = !!quotation?.deathBenefitPremium;

    const goBack = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    useEffect(() => {
        if (!quotation) {
            goBack();
        }
    }, [goBack, quotation]);

    return (
        <Container disableGutters sx={{ marginTop: { xs: '2rem', md: theme.spacing(7) }, padding: '0 1.6rem' }}>
            <Box margin={{ xs: "0", sm: "0 3rem" }}>
            <Typography
                variant="h1"
                fontSize={{ xs: '2.4rem', md: '2.4rem' }}
                fontWeight={400}
                lineHeight={{ xs: '3rem', md: '3.2rem' }}
                marginBottom={{ xs: '0.8rem', sm: '0.4rem' }}
            >
                Loan repayment details
            </Typography>
                <CreditLifeBreakdown deathBenefitOptedIn={deathBenefitOptedIn} getQuotationResponse={quotation} creditLifeSaving={creditLifeSaving} />
                <Stack direction={{ xs: 'column-reverse', sm: 'row' }} justifyContent={'space-between'} margin={{ xs: '5rem 0 5rem 0' }} spacing={'3.1rem'}>
                <TextButton onClick={() => navigate(-1)} removePadding>
                    Back
                </TextButton>
                <PrimaryButton type="submit" onClick={goToProcessing}>
                    Continue
                </PrimaryButton>
            </Stack>
            </Box>
        </Container>
    );
};

export default CreditLifeLoanRepaymentDetails;