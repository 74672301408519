import { LoanClient } from '../../../../../clients/LoanClient';

export const RedirectToTruId = async (
  loanClient: LoanClient,
  loanId: string,
  setOpenErrorDialogPopup: (open: boolean) => void,
  setIsLoading: (open: boolean) => void) => {
  try {
    setIsLoading(true);

    const truIdURL = await loanClient.getTruIdRedirectUrl(loanId);

    if (truIdURL) {
      window.location.replace(truIdURL);
    } else {
      setOpenErrorDialogPopup(true);
      setIsLoading(false);
    }
  }catch (e){
    console.error(e);
    setOpenErrorDialogPopup(true);
    setIsLoading(false);
  }
}
